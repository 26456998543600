<template>
  <div>
    <banner title="Online Radio Directories" />
    <v-container class="my-10">
      <div v-if="isMobile">
        <v-row v-for="d in directories" :key="d.name" class="justify-center align-center">
          <v-col md="7" sm="11">
            <v-card elevation="0">
              <v-img v-if="d.link" :src="d.link" :alt="d.alt"></v-img>
              <v-btn v-if="d.listen" :href="d.listen" dark elevation="6" block color="#1E91FF" class="mb-2"
                target="_blank">Listen Now</v-btn>
            </v-card>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </div>
      <div v-else align="center">
        <v-row align="center" justify="space-between">
          <v-col v-for="d in directories" :key="d.name" cols="6" class="mb-6">
            <v-card width="700" height="125">
              <v-row align="center" justify="center">
                <v-col cols="7">
                  <v-img v-if="d.link" :src="d.link" contain max-height="100" @click="d.listen"></v-img>
                </v-col>
                <v-col cols="5">
                  <v-btn bottom v-if="d.listen" :href="d.listen" dark elevation="6" color="#1E91FF"
                    target="_blank">Listen Now</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";

export default {
  name: "HowToListen",
  components: { Banner },
  data: () => ({
    model: 0,
    directories: [
      // {
      //   name: 'Shoutcast Directory',
      //   link:
      //     '<a href="http://directory.shoutcast.com/Search?query=graceway%20radio" title="Shoutcast" target="_blank"><img src="https://www.autopo.st/wp-content/uploads/2018/01/shoutcast_twitter_script_post.png" style="width:80%;height:40px;" alt="Shoutcast"></a>',
      //   listen: 'http://directory.shoutcast.com/Search?query=graceway%20radio',
      // },
      {
        name: "Internet-Radio.com",
        link: "http://www.internet-radio.com/images/internet-radio-reflection.png",
        listen: "https://www.internet-radio.com/search/?radio=graceway",
        alt: "Internet-Radio"
      },
      {
        name: "Radioguide.FM",
        link: "https://www.radioguide.fm/public/img/banners/banner8.png",
        listen: "https://www.radioguide.fm",
        alt: "Radioguide.FM"
      },
      {
        name: "Zeno.FM",
        link: "https://www.zeno.fm/widget/assets/images/elements/logo-zeno-black.png",
        listen: "https://zeno.fm/gracewayradio/",
        alt: "ZenoFM"
      },
      {
        name: "Streamitter.com",
        link: "https://radio.streamitter.com/images/link/300x40-white.png",
        listen: "https://radio.streamitter.com/station/graceway-radio-E0DS0P",
        alt: "Streamitter"
      },
      {
        name: "Raddio.net",
        link: "https://raddio.net/img/widgets_3.png",
        listen: "https://raddio.net/371658-graceway-radio/",
        alt: "Raddio.net"
      },
      {
        name: "Streamfinder.com",
        link: "http://streamfinder.com/images/streamfinder-icon.gif",
        listen: "https://www.streamfinder.com/streaming-radio/graceway-radio/77010/",
        alt: "Streamfinder.com"
      },
      {
        name: "Liveradio.ie",
        link: "https://www.liveradio.ie/liveradio-logo.png",
        listen: "https://www.liveradio.ie/stations/graceway-radio",
        alt: "Liveradio.ie"
      },
      {
        name: "Listenonlineradio.com",
        link: "https://www.listenonlineradio.com/assets/images/468x60.png",
        listen: "https://www.listenonlineradio.com/usa/graceway-radio",
        alt: "Listenonlineradio.com"
      },
      {
        name: "RadioPlug",
        link: "https://radioplug.co.uk/LOGO.png",
        listen: "https://radioplug.co.uk/channel/GraceWay-Radio",
        alt: "RadioPlug"
      },
      {
        name: "Streema",
        link: "https://streema.com/static/img/flatpages/LogoStreema.png",
        listen: "http://streema.com/radios/play/GraceWay_Radio",
        alt: "Streema"
      },
      {
        name: "Get Me Radio!",
        link: "https://www.getmeradio.com/images/assets/getmeradio_full_transparent.png",
        listen: "https://www.getmeradio.com/stations/gracewayradio-5307/?station_id=5307",
        alt: "GetMeRadio"
      },
      {
        name: "Radio Garden",
        link: "https://i.ytimg.com/vi/GEQQ-szMnkE/hqdefault.jpg",
        listen: "http://radio.garden/listen/grace-way-radio/200KscMP",
        alt: "Radio Garden"
      }
    ]
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    goToSite(url) {
      window.location.href = url;
    }
  },
  metaInfo() {
    return {
      title: "Other Ways To Listen",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "GraceWay Radio can be found in many online radio directories."
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "GraceWay Radio can be found in many online radio directories."
        },
        { vmid: "og:title", property: "og:title", content: "Other Ways To Listen" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/how-to-listen"
        }
      ]
    };
  }
};
</script>
